@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

$seafoam-blue: #5bd7d9;
$mango: #fcb731;
$disk-blue: #26668c;
$reddish-grey: #827f7f;
$vermillion: #ff1900;

$warm-grey: #979595;
$whiteFB: #fbfaff;

$marine: #032354;
$marineBlue: #002b6f;
$marine-blue: #002b6f;
$blueNavy: #1e398d;

$darkGreyBlue: #303d4d;
$dark-grey-blue: #303d4d;

$grey8: #8e8e93;

$black24: #242020;
$black30: #302b2b;
$black39: #393939;
$black16: #161313;
$pure-black: #000000;

$greyishBrown: #505050;

$very-light-pink: #bfbfbf;
$veryLightPink: #bfbfbf;

$backgroundGrey: #efefef;
$backgroundGreyLight: #dedede;

$menuBackground: #303d4d;
$buttonPrimary: #3a62c7;
$buttonPrimaryHover: #2749a1;
$buttonDanger: #e62929;
$buttonLightHover: rgba(58, 98, 199, 0.1);
$buttonDangerHover: rgba(230, 41, 41, 0.1);
$tableHover: #dae4f7;
$labelColor: #303d4d;
$checkboxChecked: #0e967d;

// LABELS
$draft: #ffa900;
$published: #0e967d;
$canceled: #e62929;
$finished: #3a62c7;
$lightGreen: #00bd70;

$background: #ebeef0;
$grey: #616161;
$subGrey: #9ba2aa;
$lightGrey: #d4dadf;
$disabled-grey: #bdbdbd;
$darkBlack: #212121;
$black: #424242;
$white: #ffffff;
$shadow: #eeeeee;

$fontSize: 14px;
$fontSizeSmall: 12px;
$fontSizeMedium: 16px;
$fontSizeMediumLarge: 18px;
$fontSizeLarge: 20px;
$fontSizeBig: 36px;

$fontFamily: Roboto, 'Roboto', sans-serif;
$shadowWidth: 1px;
// $fontSizeSmall: 1.2rem;
// $fontSizeMacro: 1rem;
// $fontSizeMicro: 0.7rem;

$spacing: 15px;
$spacingSmall: 5px;
$spacingMedium: 10px;
$spacingLarge: 20px;

@mixin withTextEllipsis() {
    white-space: nowrap;
    text-overflow: ellipsis;
}

/* credit: http://hackingui.com/front-end/a-pure-css-solution-for-multiline-text-truncation/ */
@mixin multiLineEllipsis($lineHeight: 1.2em, $lineCount: 1, $bgColor: white) {
    overflow: hidden;
    position: relative;
    line-height: $lineHeight;
    max-height: $lineHeight * $lineCount;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;

    &:before {
        content: '...';
        position: absolute;
        right: 0;
        bottom: 0;
    }
    &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 1em;
        height: 1em;
        margin-top: 0.2em;
        background: $bgColor;
    }
}
