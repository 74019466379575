@import '../../../../../../../theme.scss';

.user-info-card-root {
    display: flex;
    flex-direction: column;

    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
    background-color: $white;

  padding: $spacing $spacing 0;

  .uic-header {
        display: flex;
        flex-direction: row;

        .uic-h-avatar {
            display: flex;
            justify-content: center;
            align-items: center;

            width: 5vw;
            height: 5vw;

            margin-right: $spacing;

            border-radius: 18px;
            box-shadow: 0 2px 14px 3px rgba(0, 0, 0, 0.1);
            background-color: $marine-blue;

            font-family: $fontFamily;
            font-size: $fontSizeBig;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: center;
            color: $white;
        }

        .uic-h-names {
            display: flex;
            flex-direction: column;

            .uic-h-last-name,
            .uic-h-first-name {
                font-family: $fontFamily;
                font-size: $fontSizeLarge;
                font-weight: 600;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: normal;
                color: #414042;
            }
        }
    }

    .uic-notifs {
        margin-left: 0;

        .uic-notifs-checkbox {
            margin-left: auto;
        }

        .uic-notifs-label {
            font-family: $fontFamily;
            font-size: $fontSize;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #1a1919;
        }
    }
}
