@import '../../../../../../theme.scss';

.profile-header-menu-root {
    .pfm-button-root {
        padding: $spacingSmall $spacingLarge;

        .pfm-button-label {
            font-family: $fontFamily;
            font-size: $fontSize;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: $dark-grey-blue;

            text-transform: none;
        }

        .pfm-button-expand-icon {
            margin-left: $spacingSmall;

            transition: transform 200ms ease-in-out;

            &.open {
                transform: rotate(180deg);
            }
        }
    }
}

.profile-header-popover-paper {
    display: flex;
    flex-flow: column;

    &.showProfile {
        width: 27vw;
    }

    .profile-disconnect-button.text {
        margin: $spacing;

        &.showProfile {
            margin-left: auto;
        }

        text-decoration: underline;
        text-transform: none;

        font-family: $fontFamily;
        font-size: $fontSizeMedium;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: right;
        color: $marine-blue;
    }
}
