@import '../../../../../../../theme.scss';

.pli-item {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;

    &.pli-header {
        font-family: $fontFamily;
        font-size: $fontSize;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: -0.1px;
        color: $reddish-grey;

        background-color: #fafafa;
    }

    &.pli-row {
        font-family: $fontFamily;
        font-size: $fontSizeMedium;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.29;
        letter-spacing: -0.4px;
        color: $black;
    }

    &.pli-no-shops {
        justify-content: center;

        font-size: $fontSize;
        color: $reddish-grey;
    }

    &.about-header {
        margin-top: $spacingLarge;
    }

    &.border {
        border-bottom: 1px solid #dadadd;

        &.border-full {
            padding: $spacingSmall;
        }

        &.border-small {
            margin: $spacingSmall;
            padding-bottom: $spacingSmall;

            &:last-child {
                border: none;
            }
        }
    }

    .pli-link-chevron {
        margin-left: auto;
        margin-right: $spacingSmall;
    }
}
