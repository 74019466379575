@import '../../../../../theme';

.header-bar {
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    display: flex;
    width: 100%;
    background-color: #3a62c7;
    height: 40px;
    color: $white;
    justify-content: center;
    align-items: center;
    margin-bottom: $spacingMedium;
    font-family: Roboto;
    font-size: $fontSizeLarge;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
}
