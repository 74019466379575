@import '../../../../../theme';

.active-dir-connect-root {
    display: flex;
    flex-direction: column;
    align-items: center;

    .active-dir-message {
        font-family: $fontFamily;
        font-size: $fontSizeMedium;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $darkGreyBlue;
        margin-bottom: $spacing;
    }

    .active-dir-login-btn {
        cursor: pointer;
        margin-bottom: $spacing;
        border-radius: 2px;
        height: 40px;
        width: 248px;
        box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #4285f4;

        .label-container {
            // border: 1px solid #4285f4;
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            height: 100%;
            text-align: center;
            background-color: #4285f4;
            color: $white;
            font-size: $fontSizeMedium;
        }
    }
}
