@import '../../../theme.scss';

.shop-selection-container {
    background-color: $menuShopBackground;

    display: flex;
    justify-content: center;

    margin-top: auto;

    min-height: 40px;

    .shop-selection-content-container {
        width: 100%;

        .shop-selection-button-root {
        }

        .shop-selection-button-label {
            font-family: Roboto;
            font-size: $fontSizeMedium;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #ffffff;

            text-transform: none;

            justify-content: flex-start;

            min-height: 24px;
        }

        .shop-selection-chevron-icon {
            margin-left: auto;
            transform: rotate(90deg);
        }
    }

    .shop-selection-none {
        color: #ffffff;
        font-size: $fontSize;

        padding: $spacing 0;
    }
}
