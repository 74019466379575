@import '../../../../../theme';

.connect-content-container {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: $spacingLarge;
}
