@import '../../../../../theme';

.auth-box-error-container {
    width: 100%;
    max-width: 400px;
    display: flex;
    border-radius: 12px;
    background-color: lighten($vermillion, 40%);
    margin-bottom: $spacing;
    padding: 1em 0;

    &.hide {
        display: none;
    }

    .auth-box-errorcontent-container {
        font-family: Roboto;
        font-size: $fontSizeMedium;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $vermillion;

        padding: $spacing;
        text-align: left;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
