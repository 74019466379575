.ImpersonateButtonConnect-button {
    cursor: pointer;
    width: 100%;
    height: 45px;

    display: flex;
    align-items: center;

    border-radius: 4px;
    border: solid 1px #bfbfbf;
    background-color: #ffffff;

    .ImpersonateButtonConnect-button-label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .ImpersonateButtonConnect-icon {
            margin-left: 44px;
            margin-right: 32px;
        }

        .ImpersonateButtonConnect-title {
            margin-right: 16px;
            font-size: 14px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #000000;
        }
    }

    .ImpersonateButtonConnect-loader {
        justify-self: center;
        display: flex;
        justify-content: center;
    }
}
