@import '../../../theme.scss';

.FluizyMessage-label {
    font-family: Roboto;
    font-size: $fontSizeMedium;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #f0f4f7;
}
