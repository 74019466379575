@import "../../../theme.scss";

.pst-input-form-control {
    .pst-input-base {
        font-size: $fontSizeMedium;
        font-family: $fontFamily;

        input {
            padding: 12px 12px;
        }

        &:not(.error) input {
            color: $black;
        }

        &.error input {
            color: $vermillion;
        }

        &.bordered {
            border: 1px solid $very-light-pink;
            &.rounded {
                border-radius: 4px;
            }
        }

        &.underline {
            border-bottom: 2px solid $greyishBrown;
        }

        &.hasLabel {
            margin-top: 12px;
        }

        &:not(.focused) {
            background-color: $white;
        }

        &:not(.focused).offWhiteUnfocused {
            background-color: $whiteFB;
        }

        &.focused {
            background-color: $white;
        }

        &.centerText input {
            text-align: center;
        }

        &.adornedStart > div {
            margin-left: $spacing;
        }

        &.adornedEnd > div {
            margin-right: $spacing;
        }
    }

    .pst-input-label {
        display: block;
        color: $black;

        font-size: $fontSize;
        font-family: $fontFamily;

        position: relative;

        &.shrink {
            // otherwise we cannot have full width
            transform: none;
        }

        &.centerLabel {
            width: 100%;
            text-align: center;
        }

        &.lightLabel {
            color: $warm-grey;
        }

        &:not(.focused) {
            &.error {
                color: $black;
                &.copper,
                &.mango {
                    color: $black;
                }
            }
        }

        &.focused {
            &:not(.error) {
                color: $black;
            }
            &.error {
                color: $black;
            }
        }
    }
}
