@import '../../../theme.scss';

.FluizyLogo-login {
    width: 98px;
    height: 74px;

    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing;
}
