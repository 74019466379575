@import '../../theme.scss';

.navbar-container {
    display: flex;
    flex-direction: column;

    background-color: $menuBackground;
    box-shadow: $shadowWidth 0 0 0 $shadow;
    margin-right: $shadowWidth;

    width: 100%;
    height: 100%;

    overflow-y: auto;

    .title-container {
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: $fontFamily;
        font-weight: bold;
        font-size: $fontSizeBig;
        color: $grey;

        margin-bottom: 20px;
        margin-top: 20px;

        cursor: pointer;
    }
}
