@import '../../theme.scss';

.cd2-content-container {
    padding: $spacing;

    .cd2-close-container {
        display: flex;
        flex-direction: row;

        .cd-close-button {
            width: 42px;
            height: 42px;
            background-color: rgba(0, 0, 0, 0.3);
            border-radius: 50%;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            margin-left: auto;

            cursor: pointer;

            .cd-close-img {
                width: 18px;
                height: 18px;

                fill: #ffffff;
            }
        }
    }
    .cd2-content {
    }
}
