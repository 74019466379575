@import '../../theme.scss';

.async-page-loading-container {
    background-color: $background;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100%;

    .apl-content-container {
        height: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
    }

    .apl-loader-container {
        display: inline;
        flex: 0;
    }

    .apl-loader {
    }
}
