@import "../../../theme.scss";

.LabelWithIcon-container {
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    font-family: $fontFamily;
    align-self: flex-start;

    &.clickable {
        cursor: pointer;
    }

    &.column-direction {
        flex-direction: column;
    }

    .icon-container {
        display: flex;
        justify-content: center;
        max-width: 25px;
        max-height: 25px;

        .icon {
            width: 100%;
            height: auto;
        }
    }

    .icon-right {
        margin-left: 10px;
    }

    .icon-left {
        margin-right: 10px;
    }
}
