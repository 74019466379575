@import '../../../../theme.scss';
@import '../NavBarCategory/navbarCategory.scss';

.navbarcategory-item {
    padding-left: $nav-item-left-padding;
    padding-top: $nav-item-ver-padding;
    padding-bottom: $nav-item-ver-padding;

    text-decoration: inherit; /* no underline */

    display: flex;
    flex: 1;
    cursor: pointer;
    color: $white;
    opacity: 1;
    font-size: $fontSizeMedium;
    font-weight: 300;

    .icon-container {
        height: 24px;
        width: 24px;
        padding-bottom: 4px;
        margin-right: 15px;
    }
}

.navbarcategory-item.disabled {
    cursor: default;
    opacity: 0.1 !important;
}

.navbarcategory-item:not(.selected) {
    opacity: 0.5;

    padding-left: calc(#{$nav-item-left-bar-size} + #{$nav-item-left-padding});
}

.navbarcategory-item.selected {
    font-weight: 500;

    border-left: $nav-item-left-bar-size solid $mango;
}

.navbarcategory-item:not(.disabled):hover {
    opacity: 0.8;
}
