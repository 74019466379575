@import '../../../../../../../theme.scss';

.profile-change-password-button-root.text {
    margin: 0 $spacing $spacing;

    padding: $spacingMedium $spacing;

    border-radius: 14px;
    border: solid 2px $marine-blue;

    text-transform: none;

    font-family: $fontFamily;
    font-size: $fontSizeMedium;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: $marine;
}

.pcp-dialog-content {
    padding: 0 20%;

    .pcp-dialog-title {
        margin: $spacingLarge 0;

        font-family: $fontFamily;
        font-size: $fontSizeLarge;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $black;
    }

    .pcp-dialog-input {
        margin-top: $spacing;
    }

    .pcp-dialog-button-submit-container {
        margin: $spacingLarge $spacing $spacing;

        .pcp-dialog-button-submit.text {
            width: 100%;

            padding: 15px;

            border-radius: 16px;
            background-color: $marine-blue;

            text-transform: none;

            font-family: $fontFamily;
            font-size: $fontSizeMedium;
            font-weight: 600;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.25;
            letter-spacing: normal;
            text-align: center;
            color: $white;
        }
    }
}
