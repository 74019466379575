@import '../../../../../theme';

.authbase-page {
    display: flex;
    background-color: $background;

    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;

    .authbase-left-container {
        display: flex;
        flex: 2;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: center;
        align-items: center;

        .authbase-left-content-container {
            display: flex;
            flex: 1;
            max-width: 500px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: $spacingLarge;

            .authbase-left-logo {
                margin-bottom: $spacing;
            }
        }
    }

    .authbase-right-container {
        display: flex;
        flex: 4;
        flex-direction: column;
        align-items: center;

        padding-top: 10vh;
        padding-bottom: 10vh;
    }
}

//   Device = Most of the Smartphones Mobiles (Portrait)
//   Screen = B/w 320px to 479px
@media (min-width: 320px) and (max-width: 480px) {
    .authbase-page {
        .authbase-left-container {
            .authbase-left-content-container {
                .authbase-left-logo {
                    display: none;
                }
            }
        }
    }
}

//   Device = Low Resolution Tablets, Mobiles (Landscape)
//   Screen = B/w 481px to 767px
@media (min-width: 481px) and (max-width: 767px) {
    .authbase-page {
        .authbase-left-container {
            .authbase-left-content-container {
                .authbase-left-logo {
                    display: none;
                }
            }
        }
    }
}
