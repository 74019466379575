@import '../../../../theme.scss';

$nav-item-left-bar-size: 3px;
$nav-item-left-padding: 30px;
$nav-item-ver-padding: 6px;

.navbarcategory-container {
    display: flex;
    flex-direction: column;
    font-family: $fontFamily;
    color: $grey;
    flex-shrink: 0; // prevent from being collapsed

    &:not(:last-child) {
        margin-bottom: 7vh;
    }

    .category-title {
        color: $grey;
        font-size: $fontSizeMedium;
        padding-left: 30px;
        margin-bottom: 20px;
        font-weight: 500;
    }
}
