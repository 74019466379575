@import '../../theme.scss';

.my-loader-container {
    z-index: 999;
    display: flex;
    flex: 1;

    .my-loader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
}

.my-loader-container.left {
    justify-content: flex-start;
}

.my-loader-container.center {
    justify-content: center;
}
.my-loader-container.right {
    justify-content: flex-end;
}
