.idelink-theme {
    &.il-theme-communications {
        background-color: #fcb731;
    }
    &.il-theme-digitalmarketings {
        background-color: #002b6f;
    }
    &.il-theme-partnerships {
        background-color: #0e967d;
    }
    &.il-theme-leaflets {
        background-color: #f25940;
    }
}
