@import '../../../../../theme';

.username-connect-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .username-connect-label {
        font-family: Roboto;
        font-size: $fontSizeMedium;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $labelColor;

        margin-bottom: $spacingMedium;
    }

    .username-connect-pass-forgot-label {
        margin-top: $spacingMedium;
        margin-bottom: $spacingLarge;
        margin-left: auto;

        display: flex;
    }

    .username-connect-pass-forgot-label,
    .username-connect-pass-forgot-link {
        font-size: $fontSize;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: $reddish-grey;
        text-decoration: underline;
    }

    .username-connect-pass-forgot-link {
        cursor: pointer;
    }

    .username-connect-button,
    .username-connect-button.username-connect-override {
        font-family: Roboto;
        font-size: $fontSizeMedium;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1;
        letter-spacing: normal;
        text-align: center;
        color: #ffffff;

        margin: 0;
    }
}
