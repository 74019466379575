@import '../../../theme.scss';

$button-height: 40px;

.button-container {
    display: inline-block;
    flex-direction: column;

    .button {
        font-family: $fontFamily;
        display: flex;
        justify-content: center;
        font-size: $fontSize;

        background-color: $buttonPrimary;
        border: solid 2px $buttonPrimary;
        border-radius: 2px;
        color: $white;

        min-height: $button-height;
        height: $button-height;

        margin: $spacingSmall;
        padding-right: $spacing;
        padding-left: $spacing;
        cursor: pointer;

        align-items: center;

        &:focus {
            outline: 0;
        }

        .button-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
        }
    }
    .button.danger.light {
        border: solid 2px $buttonDanger;
        background-color: transparent;
        color: $buttonDanger;
    }

    .button.danger {
        border: solid 2px $buttonDanger;
        background-color: $buttonDanger;
        color: $white;
    }

    .button:hover:not(.disabled):not(.danger):not(.light) {
        background-color: $buttonPrimaryHover;
        border: solid 2px $buttonPrimaryHover;
    }

    .button:active:not(.disabled) {
        transform: scale(0.95);
    }

    .button.light {
        background-color: transparent;
        color: $buttonPrimary;
    }

    .button.full {
        flex: 1;
        align-self: stretch;
    }

    .button.disabled {
        opacity: 0.5;
        cursor: default;
    }

    .button.light:hover:not(.disabled) {
        background-color: $buttonLightHover;
    }

    .button.danger:hover:not(.disabled) {
        background-color: #c12020;
    }

    .button.danger.light:hover:not(.disabled) {
        background-color: $buttonDangerHover;
    }

    .disabled-info-container {
        display: flex;

        .disabled-info {
            flex-grow: 1;
            width: 0;
            padding-right: $spacingSmall;
            padding-left: $spacingSmall;
            font-size: $fontSizeSmall;
            font-family: $fontFamily;
            color: $disabled-grey;
        }
    }
}

.button-container.full {
    display: flex;
    flex: 1;
    align-self: stretch;
}

.Button-loader {
    &.light > div {
        background-color: $buttonPrimary;
    }
}
