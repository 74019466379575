@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');
@import './lib/IdeLink.Web.FrontOffice/src/lib/IdeLink.Web.Common/src/theme.scss';

$seafoam-blue: #5bd7d9;
$mango: #fcb731;
$disk-blue: #26668c;
$reddish-grey: #827f7f;
$vermillion: #ff1900;
$backgroundGrey: #efefef;
$backgroundGreyLight: #dedede;
$veryLightPink: #bfbfbf;

$menuBackground: #032354;
$buttonPrimary: #3a62c7;
$buttonPrimaryHover: #2749a1;
$buttonDanger: #e62929;
$buttonLightHover: rgba(58, 98, 199, 0.1);
$buttonDangerHover: rgba(230, 41, 41, 0.1);
$tableHover: #dae4f7;
$labelColor: #303d4d;
$checkboxChecked: #0e967d;

// LABELS
$draft: #ffa900;
$published: #0e967d;
$canceled: #e62929;
$finished: #3a62c7;
$lightGreen: #00bd70;

$background: #f0f4f7;
$grey: #616161;
$subGrey: #9ba2aa;
$lightGrey: #d4dadf;
$disabled-grey: #bdbdbd;
$darkBlack: #212121;
$black: #424242;
$white: #ffffff;
$shadow: #eeeeee;

$fontSize: 14px;
$fontSizeSmall: 12px;
$fontSizeMedium: 16px;
$fontSizeLarge: 20px;
$fontSizeBig: 36px;

$fontFamily: Roboto, 'Roboto', sans-serif;
$shadowWidth: 1px;
// $fontSizeSmall: 1.2rem;
// $fontSizeMacro: 1rem;
// $fontSizeMicro: 0.7rem;

$spacing: 15px;
$spacingSmall: 5px;
$spacingMedium: 10px;
$spacingLarge: 20px;
