@import '../../../theme.scss';

.FluizyConnect-root {
    width: 400px;

    .FluizyConnect-error {
        width: 100%;
        max-width: 100%;
    }

    .FluizyConnect-connect {
        .FluizyConnect-login-options-separator {
            margin-top: 24px;
            margin-bottom: 18px;
            display: flex;
            align-items: center;
            width: 100%;

            .FluizyConnect-login-options-separator-left {
                height: 1px;
                flex: 1;
                background-color: #bcbbc1;
            }

            .FluizyConnect-login-options-separator-center {
                margin-right: 24px;
                margin-left: 24px;
                font-family: $fontFamily;
                font-size: 12px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-transform: uppercase;
                color: #9ba2aa;
            }

            .FluizyConnect-login-options-separator-right {
                height: 1px;
                flex: 1;
                background-color: #bcbbc1;
            }
        }
        .FluizyConnect-btn-maintenance {
            margin-top: 8px;
        }

        .FluizyConnect-cgu-version-container {
            margin-top: 18px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .FluizyConnect-cgu-label-container {
                font-size: 12px;
                color: #827f7f;
                .FluizyConnect-cgu-label {
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
            .FluizyConnect-version {
                margin-top: 6px;
                font-size: 12px;
                color: #827f7f;
            }

            .FluizyConnect-idelink-logo {
                margin-top: 12px;
            }
        }
    }
}
