@import '../../../../../theme';

.google-label {
    text-align: center;
    width: 300px;
    margin-bottom: $spacingLarge;
    color: #303d4d;
}

.google-login-btn {
    cursor: pointer;
    margin-bottom: $spacing;
    border-radius: 2px;
    height: 40px;
    width: 248px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #4285f4;

    .google-logo-container {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        width: 38px;
        background-color: transparent;
        // border: 1px solid #4285f4;

        .google-logo-img {
            width: 20px;
            height: 20px;
        }
    }
    .label-container {
        // border: 1px solid #4285f4;
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-align: center;
        background-color: #4285f4;
        color: $white;
        font-size: $fontSize;
    }
}
