@import '../../theme.scss';

.idelink-text-field-form-control-root {
    &:not(.disableTopMargin) {
        margin-top: $spacing;
    }

    label {
        @include withTextEllipsis();
    }

    &.alignRight {
        label {
            left: initial;
            transform-origin: top right;
            right: 0;
        }
    }

    &.clickable:not(.disabled),
    &.clickable.disabled.noDisabledStyle {
        label {
            cursor: pointer;
        }
    }

    .idelink-text-field-root {
        font-style: inherit;
        font-variant: inherit;
        font-weight: inherit;
        font-stretch: inherit;
        font-size: inherit;
        line-height: inherit;
        font-family: inherit;

        &.disabled.noDisabledStyle {
            font-size: 20px;
            line-height: 24px;
            width: 100%;
            display: inline-block;
            position: relative;
            background-color: transparent;
            font-family: $fontFamily;
            transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
            cursor: inherit;

            color: inherit;
        }

        .idelink-text-field-input {
            font-style: inherit;
            font-variant: inherit;
            font-weight: inherit;
            font-stretch: inherit;
            font-size: inherit;
            line-height: inherit;
            font-family: inherit;

            &.disabled {
                @include withTextEllipsis();
                max-width: 100%;
            }

            &.clickable:not(.disabled),
            &.clickable.disabled.noDisabledStyle {
                cursor: pointer;
            }

            &.alignRight {
                text-align: right;
            }
        }
    }
}

.textfield-container {
    font-size: 20px;
    line-height: 24px;
    width: 100%;
    height: 72px;
    display: inline-block;
    position: relative;
    background-color: transparent;
    font-family: $fontFamily;
    transition: height 200ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    cursor: inherit !important;
}
