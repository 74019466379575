@import '../../../../../../theme.scss';

.InputPinDialog-content {
    max-width: 344px;
    width: 344px;

    display: flex;
    flex-direction: column;
    .InputPinDialog-content-header {
        width: 100%;
        display: flex;
        align-items: center;
        height: 54px;
        border-bottom: 1px solid #ebeef0;

        .InputPinDialog-content-header-icon {
            margin-left: 18px;
        }
        .InputPinDialog-content-header-title {
            margin-left: 28px;
            margin-right: 16px;
            flex: 1;

            font-family: $fontFamily;
            font-size: 18px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.28px;
            text-align: center;
            color: #1a1919;
        }
        .InputPinDialog-content-header-btn-close {
            margin-right: 9px;
            padding: 9px !important;
        }
    }

    .InputPinDialog-content-body {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 34px;
        align-items: center;

        .InputPinDialog-pin-input-container {
            width: 100%;
            display: flex;
            justify-items: center;

            .InputPinDialog-pin-input {
                margin-left: 16px;
                margin-right: 16px;
                border-radius: 4px;
                background-color: #ffffff;
                border: solid 1px #bfbfbf;
                height: 45px;
                padding-left: 12px;
                padding-right: 12px;
            }
        }

        .InputPinDialog-validate-btn-container {
            margin-top: 48px;
            margin-bottom: 38px;
            padding: 0;

            &.disabled {
                opacity: 0.5;
            }
            .InputPinDialog-validate-btn {
                height: 35px;
                margin: 0;
                background-color: #ffffff;

                border-radius: 12px;
                min-width: 148px;
                color: #002b6f;
                border: solid 2px #002b6f;

                &:hover {
                    background-color: #002b6f !important;
                    color: #ffffff;
                }
            }
        }
    }
}
